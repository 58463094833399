import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';

export default class ProspectiveClientService extends Service {
  @service store;
  @service session;

  @tracked hasRequestedAppointment;
  @tracked hasAcceptedAppointment;

  constructor() {
    super(...arguments);
    this.reset();
  }

  async #getUpcomingAppointmentCounts() {
    try {
      const adapter = this.store.adapterFor('appointment');
      return await adapter.getUpcomingAppointmentCounts();
    } catch (error) {
      console.error('Failed to fetch pending appointment counts', error); // eslint-disable-line no-console
      return {
        requested: 1,
        accepted: 1,
      };
    }
  }

  get canScheduleNewAppointments() {
    let { session, useProspectiveClientFlow, hasAcceptedAppointment, hasRequestedAppointment } =
      this;
    let canSchedule =
      !useProspectiveClientFlow || (!hasAcceptedAppointment && !hasRequestedAppointment);

    return session.currentClient?.permissions?.selfScheduling && canSchedule;
  }

  get hasProspectiveClientFeature() {
    return this.session.currentPractice.get('featureProspectiveClient');
  }

  get useProspectiveClientFlow() {
    let { session, hasProspectiveClientFeature } = this;
    return session.currentClient?.isProspectiveOrArchived && hasProspectiveClientFeature;
  }

  async updatePendingAppointmentCount() {
    let { useProspectiveClientFlow } = this;
    if (useProspectiveClientFlow) {
      let { requested, accepted } = await this.#getUpcomingAppointmentCounts();
      this.hasRequestedAppointment = !!requested;
      this.hasAcceptedAppointment = !!accepted;
    }
  }

  reset() {
    let { hasProspectiveClientFeature, useProspectiveClientFlow } = this;
    this.hasRequestedAppointment = !hasProspectiveClientFeature || !useProspectiveClientFlow;
    this.hasAcceptedAppointment = !hasProspectiveClientFeature || !useProspectiveClientFlow;
  }
}
