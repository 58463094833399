import { ACTIVE_FEEDBACK_BANNER_ID } from 'client-portal/utils/feedback-banner-events';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class SiteAppointmentsRoute extends Route {
  @service router;
  @service session;
  @service clientTimezone;
  @service prospectiveClient;

  async beforeModel(transition) {
    super.beforeModel(...arguments);

    this.clientTimezone.reset();
    this.session.requireAuthentication(transition, 'home');

    if (!this.session.currentClient.permissions.appointments) {
      this.router.transitionTo('site.home');
    }

    await this.#routeForProspectiveClientIfNecessary();
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('activeFeedbackBannerId', ACTIVE_FEEDBACK_BANNER_ID);
  }

  async #routeForProspectiveClientIfNecessary() {
    let { prospectiveClient } = this;

    if (prospectiveClient.useProspectiveClientFlow) {
      await prospectiveClient.updatePendingAppointmentCount();
      let { hasAcceptedAppointment, hasRequestedAppointment } = prospectiveClient;
      let route = hasAcceptedAppointment
        ? 'upcoming'
        : hasRequestedAppointment
          ? 'requested'
          : null;
      if (route) {
        this.router.transitionTo(`site.appointments.${route}`);
      }
    }
  }
}
